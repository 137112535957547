import { FaExchangeAlt, FaGoogle, FaPowerOff } from "react-icons/fa";
import { logoutUser, logout, callWorkflow } from "../../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { setStatus, setTimer } from "../../../features/dialerSlice";
import Dropdown from "./Dropdown";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { verified_crm_logo } from "../../../images";
import { sendIframeMsg } from "../../../util/iFrame";
import { toast } from "react-toastify";
import { verifiedMenu } from "./menu.config";
import { FcOk } from "react-icons/fc";
import api from "../../../services/api";
import { FaEnvelope } from "react-icons/fa";
const Sidebar2 = ({
  setIsSwitchOffice,
  show_navlabel,
  setLogingOut,
  isDroping,
  isDroped,
  isDropingOut,
  isDialer,
}) => {
  const { status, isJobDone } = useSelector((state) => state.dialer);
  const { workFlowList } = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));
  const [workFlowSubmenu, setWorkFlowSubmenu] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const logoutHandler = async () => {
    setLogingOut(true);
    await dispatch(logout());
    sendIframeMsg({
      action: "logout",
      user: user?.dialer_user_info?.dialer_user,
      pass: user?.dialer_user_info?.dialer_pass,
    });
    dispatch(logoutUser());
    dispatch(setStatus(""));
    dispatch(setTimer(""));
    setLogingOut(false);
  };
  const showError = () => {
    if (status === "INCALL" || status === "DEAD") {
      toast.info("Please hangup your current call before you proceed", {
        position: "top-center",
      });
    } else if (status === "DISPO") {
      toast.error("Please select a disposition...", {
        position: "top-center",
      });
    }
  };
  const isAllow = (item) => {
    if (user?.permissions?.length > 0) {
      if (
        user?.permissions?.some(
          (obj) => obj === item?.label || item?.label?.includes("Beta")
        )
      ) {
        return true;
      }
      return false;
    }
  };

  const getWorkFlow = async () => {
    const res = await api.get("/api/workflowstatus/parent_status/get_all");
    if (res.status === 200) {
      let newSubMenu = verifiedMenu.find((e) => e.label === "Workflow Beta");
      let oldSubMenu = verifiedMenu.filter((e) => e.label !== "Workflow Beta");
      newSubMenu.children = [];
      res.data.map((child) =>
        newSubMenu.children.push({
          id: child._id,
          to: child._id,
          label: child.title,
          Icon: FaEnvelope,
          task: "Workflow Beta",
          permission: ["ALL"],
        })
      );
      setWorkFlowSubmenu([...oldSubMenu, newSubMenu]);
    }
  };

  const syncWithGoogle = async () => {
    if (isSynced)
      return toast.error("Your account already synced with google.");
    try {
      window.location.href = `${process.env.REACT_APP_BACKEND_URL}api/google/auth`;
    } catch (err) {
      console.log("Error while syncing with google", err);
    }
  };
  const location = useLocation();
  const [clickedOn, setClickedOn] = React.useState("");
  React.useEffect(() => {
    dispatch(callWorkflow(true));
    if (!workFlowList) {
      getWorkFlow();
    }
  }, []);
  React.useEffect(() => {
    let route = location.pathname.split("/")[1];
    setClickedOn(`/${route}`);
  }, [location.pathname]);
  const disbale =
    status && status !== "PAUSE" && status !== "WAIT" && !isJobDone;
  const isSynced =
    (user?.synced_with_google && user?.synced_with_google === true) ||
    user?.synced_with_google === "true";
  return (
    <div className={``}>
      <div
        className={`${
          !isDroping || isDropingOut
            ? "bg-[#f7f7f7] "
            : "bg-[#d5d5d5] shadow-[0_0.125rem_0.5rem_rgb(0_0_0_/30%),_0_0.0625rem_0.125rem_rgb(0_0_0_/20%)] rounded-md"
        } fixed  w-[235px] top-0 bottom-0 border-r-2 border-[#e8e9eb] z-[99] !overflow-y-hidden transition-all`}
      >
        <div
          className={`p-3.7 pb-1 border-b border-[#d5d5d5] !overflow-y-hidden ${
            user.company_name === "Verified CRM" ? "pt-1" : ""
          }`}
        >
          <Link to="/">
            <img src={verified_crm_logo} alt="Verified CRM" />
          </Link>
          <div className="!mt-2 text-center flex flex-col w-full justify-center">
            <small>
              {" "}
              {user?.company_name && user?.company_name === "Verified CRM"
                ? "Priority Gold"
                : user?.company_name}
            </small>
          </div>
        </div>

        <div
          className={`${
            isDroped && isDialer === "flex"
              ? "h-[calc(100vh-450px)]"
              : "h-[calc(100vh-150px)]"
          } overflow-y-auto scrollbar`}
        >
          {verifiedMenu.map((item, key) => {
            const hasVisibleChildren =
              item?.children && item?.children.some((child) => isAllow(child));
            const { id = null, label, to, Icon, children, onClick } = item;
            return isAllow(item) && (hasVisibleChildren || !item?.children) ? (
              <div className="w-full" key={key}>
                {item?.children ? (
                  <>
                    <div
                      className="transition-all page_link group hover:text-[#828282] hover:no-underline text-[#828282] transition flex items-center justify-between py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
                      onClick={() => {
                        setClickedOn(clickedOn === to ? "" : to);
                        setIsOpen(!isOpen);
                      }}
                    >
                      <div className="flex items-center">
                        <Icon className="icons group-hover:text-primary-100" />
                        {show_navlabel && (
                          <span className="!ml-1">{label}</span>
                        )}
                      </div>
                      <FaAngleDown />
                    </div>
                    {clickedOn === to && !isDroping && !disbale && (
                      <Dropdown
                        id={id}
                        show_navlabel={show_navlabel}
                        label={label}
                        to={to}
                        Icon={Icon}
                        options={children}
                        key={key}
                        isDroping={isDroping}
                      />
                    )}
                  </>
                ) : (
                  <NavLink
                    title={label}
                    to={disbale ? location?.pathname : to}
                    onClick={disbale ? showError : onClick}
                    className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
                  >
                    <Icon className="icons group-hover:text-primary-100" />

                    {show_navlabel && <span className="ml-1">{label}</span>}
                  </NavLink>
                )}
              </div>
            ) : null;
          })}
          {user?.company_name !== "Verified CRM" &&
            isAllow({ label: "Switch Office", task: "Switch Office" }) && (
              <NavLink
                to
                className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] px-3 w-full outline-none border-0 cursor-pointer transition-all"
                onClick={() => (disbale ? showError : setIsSwitchOffice(true))}
              >
                <FaExchangeAlt className="group-hover:text-primary-100" />
                {show_navlabel && <span className="ml-1">Switch Office</span>}
              </NavLink>
            )}
          {isAllow({ label: "Sync with Google", task: "Sync with Google" }) && (
            <NavLink
              to
              className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex justify-between items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
              onClick={syncWithGoogle}
            >
              <div>
                <FaGoogle className="group-hover:text-primary-100" />
                {show_navlabel && (
                  <span className="ml-1">
                    {isSynced ? `Synced with Google` : `Sync with Google`}
                  </span>
                )}
              </div>
              {isSynced ? <FcOk className="ml-" /> : null}
            </NavLink>
          )}
          <NavLink
            to
            onClick={disbale ? showError : logoutHandler}
            className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
          >
            <FaPowerOff className="group-hover:text-primary-100" />
            {show_navlabel && <span className="ml-1">Logout</span>}
          </NavLink>
        </div>
        {isDroping && !isDropingOut && (
          <div className="bg-[#f7f7f7] h-[300px] w-[210px] absolute bottom-1 ml-1 shadow-[0_0.125rem_0.5rem_rgb(0_0_0_/30%),_0_0.0625rem_0.125rem_rgb(0_0_0_/20%)] rounded-md"></div>
        )}
        <div className="flex justify-center text-center">
          <small className="text-center">Beta Version 0.5.5</small>
        </div>
      </div>
    </div>
  );
};

export default Sidebar2;
