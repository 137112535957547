import React from "react";
import { FaPlus } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import Button from "../../atoms/Button";
function Breadcrumbs({
  breadcrumb,
  onClick,
  topBtn,
  buttonTitle,
  addIcon,
  AdditionalButtons,
  additionalButtonsProps,
}) {
  let location = useLocation();
  const pathname = breadcrumb ? breadcrumb : location.pathname;
  const paths = pathname?.split("/").filter(Boolean);
  const breadcrumbs = paths?.map((path, i) => {
    let route = `/${paths.slice(0, i + 1).join("/")}`;

    return (
      <span key={i}>
        <Link
          to={i < paths.length - 1 ? route : ""}
          className={`text-sm font-medium font-mont text-heading capitalize ${
            i < paths.length - 1
              ? "text-sm font-semibold"
              : "hover:no-underline !text-gray-500 cursor-not-allowed"
          }`}
        >
          {path.replaceAll("_", " ")}
        </Link>
        {i < paths.length - 1 && <span> {">"} </span>}
      </span>
    );
  });
  const {
    lead_type,
    lead_id,
    openReplicateFormHandler,
    formik,
    leadType,
    cancelFormHandler,
  } = additionalButtonsProps || {};
  return (
    <div className="flex flex-wrap justify-between items-center">
      <div className="flex flex-wrap items-center gap-x-1">{breadcrumbs}</div>

      <div className="flex text-right">
        {topBtn && (
          <button
            type="button"
            className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
            onClick={onClick}
            disabled={!topBtn}
          >
            {addIcon !== "No" ? <FaPlus className="mr-2" /> : null}
            {buttonTitle ? buttonTitle : "Add New"}
          </button>
        )}
        {/* <Button
        text="Test"
        onClick={() => console.log("Testing")}
        variant="btn_submit"
      /> */}
        {AdditionalButtons ? (
          <div className="flex text-right">
            {lead_type !== "new_record" && lead_id ? (
              <Button
                // className="py-1 !px-1 w-fit bg-primary-100 border-primary-100 text-white rounded-sm disabled:bg-[#ffd56b] text-sm font-semibol"
                onClick={() =>
                  openReplicateFormHandler(formik.values.lead_number)
                }
                variant="btn_submit"
                text={`Copy this ${leadType}`}
              />
            ) : null}

            <Button
              text="Cancel"
              className="mx-2"
              onClick={() => cancelFormHandler(0)}
              variant="btn_cancel"
            />

            <Button
              text="Submit"
              className=""
              onClick={formik?.handleSubmit}
              // onClick={()=>console.log('Test')}

              variant="btn_submit"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default Breadcrumbs;
