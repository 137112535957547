import {
  FaBook,
  FaCog,
  FaCommentDollar,
  FaEnvelope,
  FaUserFriends,
  FaUserCog,
  FaPhoneAlt,
  FaUsersCog,
  FaUserCheck,
} from "react-icons/fa";
import { MdGroupAdd, MdMenu } from "react-icons/md";
export const verifiedMenu = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/leads/list",
    label: "Leads",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/deals/list",
    label: "Deals",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/webforms",
    label: "Web Forms",
    Icon: FaUserFriends,
    task: "Web Forms",
    permission: ["ALL"],
  },
  {
    to: "/priority_view/leads",
    label: "Priority View",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["ALL"],
  },
  {
    to: "/reports",
    label: "Report",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/workflow/list",
    label: "Workflow Report",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/workflowbeta",
    label: "Workflow Beta",
    Icon: FaCommentDollar,
    task: "Workflow Beta",
    permission: ["ALL"],
    children: []
  },
  {
    to: "/products",
    label: "Products",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/customers",
    label: "Customers",
    Icon: FaCommentDollar,
    task: "Scheduler",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/settings",
    label: "Settings",
    Icon: FaUsersCog,
    task: "Settings",
    permission: ["ALL"],
    children: [
      {
        to: "inventory",
        label: "Inventory",
        Icon: FaEnvelope,
        task: "List Email Template",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "vendor_price",
        label: "Vendor Price",
        Icon: FaCommentDollar,
        task: "Vendor Price",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "commission_spread",
        label: "Commission Spread",
        Icon: FaCommentDollar,
        task: "Commission Spread",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "docusign_templates",
        label: "Docusign Templates",
        Icon: FaEnvelope,
        task: "List Email Template",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "workflow_status",
        label: "Workflow",
        Icon: FaCommentDollar,
        task: "Scheduler",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "lead_source",
        label: "Lead Source",
        Icon: MdMenu,
        task: "List Lead",
        permission: ["ALL"],
      },
      {
        to: "lead_source_type",
        label: "Lead Source Type",
        Icon: MdMenu,
        task: "List Lead",
        permission: ["ALL"],
      },
      {
        to: "distribution_program",
        label: "Distribution Program",
        Icon: MdMenu,
        task: "List User",
        permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
      },
      {
        to: "administration",
        label: "Administration",
        Icon: FaCog,
        task: "List Lead",
        permission: ["ALL"],
        children: [
          {
            to: "users",
            label: "Users & Groups",
            Icon: MdGroupAdd,
            task: "List User",
            permission: ["SUPER ADMINISTRATOR", "BM", "ADMIN"],
          },
          {
            to: "roles",
            label: "User Roles",
            Icon: FaUserCog,
            task: "Administration",
            permission: ["SUPER ADMINISTRATOR"],
          },
          {
            to: "system_settings",
            label: "System Settings",
            Icon: FaUserCheck,
            task: "Administration",
            permission: ["SUPER ADMINISTRATOR"],
          },
          {
            to: "permissions",
            label: "Permissions",
            Icon: FaUserCog,
            task: "Administration",
            permission: ["SUPER ADMINISTRATOR"],
          },
          {
            to: "lead_status",
            label: "Lead Status",
            Icon: FaUserCheck,
            task: "List Lead Status",
            permission: ["ALL"],
          },
          {
            to: "lead_actions",
            label: "Lead Actions",
            Icon: FaUserCheck,
            task: "List Lead Status",
            permission: ["ALL"],
          },
          {
            to: "email_templates",
            label: "Email Templates",
            Icon: FaEnvelope,
            task: "List Email",
            permission: ["ALL"],
          },
          {
            to: "sms_templates",
            label: "SMS Templates",
            Icon: FaEnvelope,
            task: "List Email",
            permission: ["ALL"],
          },
        ],
      },
    ],
  },
  {
    to: "/realtime",
    label: "Real Time",
    Icon: FaCommentDollar,
    task: "Get Real Time Job Recording",
    permission: ["SUPER ADMINISTRATOR", "BM"],
  },
  {
    to: "/callcenter",
    label: "Call Center",
    Icon: FaPhoneAlt,
    task: "List Call Center",
    permission: ["SUPER ADMINISTRATOR", "BM"],
    children: [
      {
        to: "campaigns",
        label: "Campaigns",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "inbound_groups",
        label: "Ring Groups",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "dids",
        label: "DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "purchase_dids",
        label: "Purchase DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "outbound_dids",
        label: "Outbound DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "audio_files",
        label: "Audio Files",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "call_menu",
        label: "Call Menu",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      {
        to: "spam_numbers",
        label: "Spam Numbers",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["SUPER ADMINISTRATOR", "BM"],
      },
      // {
      //   to: "reports",
      //   label: "Report",
      //   Icon: FaBook,
      //   task: "Geo Location",
      //   permission: ["SUPER ADMINISTRATOR", "BM"],
      //   children: [
      //     {
      //       to: "agent_performance_report",
      //       label: "Agent Performance",
      //       Icon: FaBook,
      //       task: "Geo Location",
      //       permission: ["SUPER ADMINISTRATOR", "BM"],
      //     },
      //     {
      //       to: "call_summary_report",
      //       label: "Call Summary",
      //       Icon: FaBook,
      //       task: "Geo Location",
      //       permission: ["SUPER ADMINISTRATOR", "BM"],
      //     },
      //     {
      //       to: "call_logs",
      //       label: "Call Logs",
      //       Icon: FaBook,
      //       task: "Geo Location",
      //       permission: ["SUPER ADMINISTRATOR", "BM"],
      //     },
      //     {
      //       to: "inbound_forwarding_report",
      //       label: "Inbound Forwarding",
      //       Icon: FaBook,
      //       task: "Geo Location",
      //       permission: ["SUPER ADMINISTRATOR", "BM"],
      //     },
      //   ],
      // },
    ],
  },
];
