import api from "./api";

// export const getDids = async (data) => {
//   return await api.post(`/api/cc/dids`, data);
// };
export const getDids = async (data) => {
  return await api.post(`/api/cc/dids/get_dids`, data);
};

export const createDids = async (data) => {
  return await api.post("/api/cc/dids", data);
};
export const updateDids = async (data) => {
  return await api.put("/api/cc/dids", data);
};
export const deleteDids = async (id) => {
  return await api.delete("/api/cc/dids", { data: { id } });
};
export const getAllCCCustomers = async () => {
  return await api.get("/api/connectcx/getAllCustomers");
};
export const getCCCard = async () => {
  return await api.get("/api/connectcx/getCards");
};
export const getCCProviders = async () => {
  return await api.get("/api/connectcx/getProviders");
};
export const getPurchaseDidsList = async (data) => {
  return await api.post("/api/cc/dids/purchase_did_list",data);
};