import { Dropdown } from "primereact/dropdown";
const FormSelectInput = ({
  name,
  label,
  formik,
  options = [],
  valueProp,
  labelProp,
  convertor,
  disabled,
  onChange,
  errors,
  nullOption,
  value,
  required,
  alternatelabelProp,
  className = "",
  shrink,
}) => {
  const handleChange = (e) => {
    let newValue = e.value;
    if (convertor) newValue = convertor(newValue);

    if (formik) {
      formik.setFieldValue(name, newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
  };
  const isTruthy = [value, formik?.values?.[name], shrink].some(
    (val) =>
      val !== undefined &&
      val !== null &&
      (typeof val === "boolean" || Boolean(val))
  );
  return (
    <div className="relative w-full">
      <label
        className={`absolute left-3  -translate-y-1/2 bg-white px-1 text-gray-500 transition-all duration-300 z-10 truncate max-w-[80%] pointer-events-none ${
          isTruthy ? "top-0 text-xs text-blue-500" : "top-1/2 text-base"
        }`}
      >
        {required ? `${label} *` : label}
      </label>
      <Dropdown
        value={value ? value : value === "" ? null : formik?.values[name]}
        onChange={handleChange}
        // options={[{ [valueProp]: "", [labelProp]: "Select..." }, ...options]}
        options={options}
        optionLabel={(option) =>
          `${option?.[labelProp] || ""} ${option?.[alternatelabelProp] || ""}`
        }
        disabled={disabled}
        filter={options && options?.length < 10 ? false : true}
        optionValue={valueProp}
        // showClear
        className={`w-full border !border-[#a9a9a9] rounded-md focus:!border-2 focus:!border-[#a9a9a9] !shadow-none focus:!shadow-none focus:!ring-0 focus:ring-2 focus:ring-[#a9a9a9] ${className}`}
      />
      {/* Formik Validation Errors */}
      {formik?.errors[name] && formik?.touched[name] && (
        <small className="text-xs text-red-600 mt-1 pl-4">
          {formik.errors[name]}
        </small>
      )}
      {/* Additional Error Handling */}
      {Array.isArray(errors) &&
        errors.map((error, index) =>
          error[name] ? (
            <small key={index} className="text-xs text-red-600 mt-1 pl-4">
              {error[name]
                .replaceAll(`"${name}"`, label)
                .replaceAll(name, label)}
            </small>
          ) : null
        )}
      {errors && typeof errors === "object" && errors[name] && (
        <small className="text-xs text-red-600 mt-1 pl-4">
          {errors[name][0]}
        </small>
      )}
    </div>
  );
};

export default FormSelectInput;
