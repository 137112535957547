import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getvendorPrice, getcommissionSpread } from "../services/vendorPriceService";

const initialState = {
    isError: false,
    isLoading: false,
    record: [],
    commissionRecord: [],
    message: "",
};
const getErrorMessage = (error) => {
    return (
        error?.response?.data?.error ||
        (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
        error?.response?.data?.message ||
        error?.response?.data ||
        error.toString()
    );
};
// Get Vendor Price
export const get_vendorPrice = createAsyncThunk(
    "get_vendorPrice",
    async (data, thunkAPI) => {
        try {
            return await getvendorPrice(data);
        } catch (error) {
            const message = getErrorMessage(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get Commision Spread
export const get_commissionSpread = createAsyncThunk(
    "get_commissionSpread",
    async (data, thunkAPI) => {
        try {
            return await getcommissionSpread(data);
        } catch (error) {
            const message = getErrorMessage(error);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const vendorPriceSlice = createSlice({
    name: "vendorPrice",
    initialState,
    reducers: {
        vendorPriceReset: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.record = [];
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_vendorPrice.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(get_vendorPrice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.record = action.payload?.data;
            })
            .addCase(get_vendorPrice.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(get_commissionSpread.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(get_commissionSpread.fulfilled, (state, action) => {
                state.isLoading = false;
                state.commissionRecord = action.payload?.data;
            })
            .addCase(get_commissionSpread.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export const { vendorPriceReset } = vendorPriceSlice.actions;
export default vendorPriceSlice.reducer;
