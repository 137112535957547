import React, { useRef } from "react";
import Button from "../../atoms/Button";
function useVisible(onCancelModal) {
  const modalRef = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelModal();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelModal();
    }
  };

  // React.useEffect(() => {
  //   document.addEventListener("keydown", handleHideDropdown, true);
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("keydown", handleHideDropdown, true);
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // });

  return { modalRef };
}

const Modal = (props) => {
  const { modalRef } = useVisible(props?.onClick);

  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className={`relative ${props?.mainClass}`}>
      <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
      <button
        onClick={props.onCancelModal}
        className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
      <div
        className={`relat z-10 w-auto w-full md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white ${props.modalClass}`}
        ref={modalRef}
      >
        
        <div className={`pt-0 max-w-[994px] h-full px-3.5 m-auto ${props.modalBodyClass}`}>
          {!props.hideHeader && (
            <header className={`${props.titleClass?props.titleClass:"bg-primary-100"} h-[60px] flex items-center justify-center shadow-lg`}>
              <h1 className={`text-2xl ${props.titleTextClass?props.titleTextClass:"text-white"} m-4 font-medium`}>
                {props.isUpdate
                  ? props.title.replaceAll("Add", "Edit")
                  : props.title}
              </h1>
            </header>
          )}
          <div className={`md:p-4 ${props?.childrenClass}`}>{props.children}</div>
          {!props.hideButtons && (
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={props.onCancelModal}
                variant="btn_cancel"
              />
              <Button
                text={"Submit"}
                className=""
                onClick={props.onSubmit}
                isLoading={props?.isLoading}
                variant="btn_submit"
              />
            </div>
          )}
          {props.CloseOnly && (
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={props.onCancelModal}
                variant="btn_cancel"
              />
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};
export default Modal;
